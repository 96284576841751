import React from 'react';
import {
  ServicesContainer,
  ServicesH1,
} from './ServiceElements';
import './Countdown.css';
import iarlaith from '../../images/iarlaith.JPG';

const Service = () => {
  return (
    <ServicesContainer id='service'>
      <ServicesH1>Shop</ServicesH1>
      <img 
        src={iarlaith} 
        alt="Iarlaith" 
        style={{
          width: '100%',
          maxWidth: '300px',
          display: 'block',
          margin: '0 auto 20px', // Center the image and add space below
          borderRadius: '10px', // Rounded corners for the image
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Add a subtle shadow
        }} 
      />
      <p style={{
        fontSize: '1.2rem',
        color: '#333',
        backgroundColor: '#f4f4f4',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        maxWidth: '600px',
        margin: '0 auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontWeight: 'bold'
      }}>
        Thank you to all for supporting our first drop. 
Let us know if you like to see a second one.      </p>
    </ServicesContainer>
  );
};

export default Service;
